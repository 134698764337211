import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useSessionContext from "@/hooks/useSesionContext";
import { userAction } from "@/store/user";
import { cartStoresActions } from "@/store/cartStore";
import { checkoutAction } from "@/store/checkout";
import useAppDispatch from "@/hooks/useAppDispatch";
import Analytics from "@/utils/Analytics";
import { popupsRemunderAction } from "@/store/PopUpsReminderSlice";
import Cookies from "js-cookie";
import { storeGlobalActions } from "@/store/actions";

const STATE = "logout";

/**
 * This hook logs out the user.
 * @returns a function that logs out the user.
 */
export const useLogout = () => {
  const { setUserToken } = useSessionContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state: logoutState } = useLocation();

  useEffect(() => {
    if (logoutState === STATE) {
      logoutActions();

      // Clear the state logout
      navigate("/", { replace: true, state: null });
    }
  }, [logoutState]);

  const logoutActions = () => {
    setUserToken(null);
    dispatch(userAction.emptyUser());
    dispatch(cartStoresActions.setInitialLocation());
    dispatch(cartStoresActions.emptyCart({ emptyStore: true }));
    dispatch(cartStoresActions.emptyAvailability());
    dispatch(popupsRemunderAction.emptyPopUpsReminder());
    dispatch(cartStoresActions.emptyLastUsedAddresses());
    dispatch(checkoutAction.resetState());
    dispatch(storeGlobalActions.emptyCheckout());
    void Analytics.Reset();
    Cookies.remove("authTokenPlus");
  };

  const logout = () => {
    navigate("/", {
      state: STATE,
    });
  };

  return { logout, logoutActions };
};
