import Heading from "@/components/Atoms/Heading";
import Paragraph from "@/components/Atoms/Paragraph";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import Input from "@/components/Molecules/Input";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import Button from "@/components/Atoms/Button";
import Notification from "@/components/Atoms/Notification";
import {
  StyledActionsWrapper,
  StyledBodyInfoModal,
  StyledNotificationBox,
  StyledPrimaryActionsWrapper,
  StyledWrapperContent,
  StyledWrapperForm,
} from "@/components/Organisms/AuthStepsModal/styles";
import SelectDocumentType from "@/components/Molecules/SelectDocumentType";
import { Controller, useForm } from "custom-hook-form";
import StepOrBackHeader from "@/components/Atoms/StepOrBackHeader";
import IconChevronLeft from "@/components/Icons/V2/Arrows/chevron-left-normal.svg?react";

export interface SignUpUserDetailsData {
  first_name: string;
  last_name: string;
  identification: string;
  identification_type: string;
}

interface SignUpUserInfoProps {
  onClose: () => void;
  backStep: () => void;
  onSubmit: (data: SignUpUserDetailsData) => void;
  fetchingError: string[] | string;
}

const SignUpUserDetails = ({ onClose, backStep, onSubmit, fetchingError }: SignUpUserInfoProps) => {
  const isMobile = useMediaQuery("(max-width:{md})");
  const isLarge = useMediaQuery("(min-width: {large})");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpUserDetailsData>({
    mode: "onSubmit",
  });

  return (
    <StyledWrapperForm onSubmit={handleSubmit(onSubmit)}>
      <StyledWrapperContent>
        {!isMobile && (
          <div tw="flex items-center justify-between">
            <Button
              sizeVariant="sm"
              color="secondary"
              variant="text"
              startIcon={<IconChevronLeft />}
              onClick={backStep}
              tw="p-0"
              type="button"
            >
              Regresar
            </Button>
            <IconClose tw="h-ml w-ml justify-self-end cursor-pointer" onClick={onClose} />
          </div>
        )}
        {isMobile && (
          <div tw="-mt-s -mx-m">
            <StepOrBackHeader onClose={onClose} onBack={backStep} />
          </div>
        )}
        <StyledBodyInfoModal>
          <Heading type="h4">Datos de facturación</Heading>
          <Paragraph sizeVariant="md">
            Ingresa los siguientes datos para generar la factura electrónica.
          </Paragraph>
        </StyledBodyInfoModal>
        <Input
          label="Nombre"
          placeholder="Ingresa nombre *"
          type="text"
          size={isLarge ? "large" : "medium"}
          {...register("first_name", {
            required: "Tu nombre es requerido",
          })}
          errors={Boolean(errors.first_name)}
          textHelp={errors.first_name?.message?.toString()}
        />
        <Input
          label="Apellido"
          placeholder="Ingresa apellido *"
          type="text"
          size={isLarge ? "large" : "medium"}
          {...register("last_name", {
            required: "Tu apellido es requerido",
          })}
          errors={Boolean(errors.last_name)}
          textHelp={errors.last_name?.message?.toString()}
        />
        <Controller
          name="identification_type"
          control={control}
          rules={{ required: "El tipo de documento es requerido" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SelectDocumentType
              onOptionSelect={(selectedOption) => onChange(selectedOption?.value)}
              selectedOption={value}
              errors={Boolean(error)}
              textHelp={error?.message?.toString() ? error?.message?.toString() : ""}
              size={isLarge ? "large" : "medium"}
            />
          )}
        />
        <Input
          label="Número de documento"
          placeholder="Ingresa número de documento *"
          type="number"
          size={isLarge ? "large" : "medium"}
          {...register("identification", {
            required: "Tu número de documento es requerido",
            minLength: { value: 4, message: "Al menos 4 caracteres" },
          })}
          errors={Boolean(errors.identification)}
          textHelp={errors.identification?.message?.toString()}
        />
      </StyledWrapperContent>
      <StyledActionsWrapper>
        <StyledPrimaryActionsWrapper>
          <Button sizeVariant="lg" color="secondary" type="submit" fullWidth>
            Finalizar
          </Button>
        </StyledPrimaryActionsWrapper>
        <StyledNotificationBox visible={!!fetchingError && fetchingError.length > 0}>
          <Notification type="error" messages={fetchingError} hFull />
        </StyledNotificationBox>
      </StyledActionsWrapper>
    </StyledWrapperForm>
  );
};

export default SignUpUserDetails;
