import Analytics from "@/utils/Analytics";
import { retryOperation } from "./retryOperation";

export const openChat = async () => {
  await retryOperation(async () => {
    if (!window?.$crisp) {
      throw new Error("Crisp is not loaded");
    }

    window.$crisp.push(["do", "chat:open"]);
    Analytics.CustomerCareMessage({ channel: "Crisp" });
  });
};
