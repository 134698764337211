export const retryOperation = async <T>(
  operation: () => Promise<T>,
  maxAttempts = 5,
  delay = 1000
): Promise<T> => {
  let attempts = 0;

  while (attempts < maxAttempts) {
    try {
      const result = await operation();
      return result;
    } catch (error) {
      attempts++;
      if (attempts >= maxAttempts) {
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
  return null as T;
};
