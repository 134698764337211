import posthog from "posthog-js";
import detectDeviceType from "./detectDeviceType";
import { formatDate } from "./date";
import type {
  ISendEventAddPaymentInfo,
  ISendEventGenerateLeadForm,
  ISendEventOrderCompleted,
  ISendEventPurchaseDiscountInfo,
  ISendEventPurchaseUserInfo,
  ISendEventShipingInfoAddress,
  ISendEventShipingInfoMethod,
  ISendEventShipingInfoUser,
  ISendEventViewPromotionBasic,
  TUserType,
} from "@/types/analytics";
import { CURRENCY, PINTEREST_APP_ID } from "@/recourses/constants";
import { cleanNullValues } from "./orders";
import { ECheckoutURL } from "@/hooks/useCheckout/types";
import { retryOperation } from "./retryOperation";

// Used for get the category page in useAnalytics Hook
const allPagesViewPage = [
  {
    startWith: "/p/",
    page: "product",
  },
  {
    startWith: ECheckoutURL.ADDRESS_PAGE,
    page: "checkout",
  },
  {
    startWith: "/c/",
    page: "category",
  },
  {
    startWith: "/h/",
    page: "hub",
  },
  {
    startWith: "/b/",
    page: "brand",
  },
  {
    startWith: "/search",
    page: "search",
  },
  {
    startWith: "/orders/",
    page: "order",
  },
  {
    startWith: "/orders",
    page: "orders",
  },
  {
    startWith: "/profile",
    page: "profile",
  },
  {
    startWith: "/profile/plus",
    page: "plus",
  },
  {
    startWith: "/referral",
    page: "referral",
  },
  {
    startWith: "/payment-method/validate",
    page: "payment-method-validate",
  },
  {
    startWith: "/referred",
    page: "referred",
  },
  {
    startWith: "/terms",
    page: "terms",
  },
  {
    startWith: "/policy",
    page: "policy",
  },
  {
    startWith: "/flash",
    page: "flash",
  },
  {
    startWith: "/gift-cards/buy",
    page: "gift-card-buy",
  },
  {
    startWith: "/gift-cards/redeem",
    page: "gift-card-redeem",
  },
  {
    startWith: "/promo/",
    page: "promo",
  },
];

/* Old analytic */
export const sendEventOrderCompleted = async ({
  currency,
  value,
  products,
  order,
  coupon,
  storeId,
}: ISendEventOrderCompleted) => {
  const hasPinterestAppID = PINTEREST_APP_ID?.trim() !== "";
  // Pre processing;
  //    To FB-Pixel
  const skus = products.map((item: any) => {
    return item.productSku;
  });

  //    To frizbit
  const frizbitProducts = products.map((item: any) => {
    return {
      id: item.productSku,
      name: item.productName,
      sku: item.productSku,
    };
  });

  //    To Pinterest
  const pinterestProducts = products.map((item: IItem) => {
    return {
      product_name: item.productName,
      product_id: item.productSku,
      // product_category: item.categories.map((category) => category ).join(", "),
      product_price: item.costPrice,
      product_quantity: item.quantity,
    };
  });

  /* Recombee setup */

  /* Calls */

  posthog.capture("Order Completed", {
    currency,
    value,
    products,
    coupon: coupon ? coupon.code : null,
  });
  window.gtag(
    "event",
    "purchase",
    cleanNullValues({
      transaction_id: order.id,
      affiliation: order.store.name,
      currency,
      value,
      coupon: coupon ? coupon.code : null,
      tax: order.items[0].tax,
      shipping: order.totalService.gross,
      items: order.items.map((item) => {
        return cleanNullValues({
          id: item.id,
          name: item.productName,
          brand: item.productBrand,
          category: item.categories.join("/"),
          sku: item.productSku,
          quantity: item.quantity,
          price: item.unitPriceGross,
        });
      }),
    })
  );
  // products.forEach((item) => {
  //   const recombeeOpts = {
  //     cascadeCreate: true,
  //     amount: item.quantity,
  //     price: Number(item.unitPriceGross) * item.quantity,
  //   };
  // });

  await Promise.all([
    // Facebook Pixel
    retryOperation(async () => {
      if (!window?.fbq) {
        throw new Error("Facebook Pixel not ready");
      }
      window.fbq("track", "Purchase", {
        content_ids: skus,
        content_type: "product_group",
        // contents: products,
        currency: CURRENCY,
        num_items: products.length,
        value,
        coupon: coupon ? coupon.code : null,
        storeId,
      });
    }),

    // Klaviyo
    retryOperation(async () => {
      if (!window?._learnq) {
        throw new Error("Klaviyo not ready");
      }
      const ids = products.map((item: any) => {
        return item.id;
      });

      // Klaviyo
      window._learnq.push([
        "track",
        "Purchase",
        {
          productIds: ids,
          currency,
          numItems: products.length,
          $value: value,
          coupon: coupon ? coupon.code : null,
          storeId,
        },
      ]);
    }),

    // Frizbit
    retryOperation(async () => {
      if (!window?.frizbit) {
        throw new Error("Frizbit not ready");
      }
      window.frizbit?.track("productsPurchased", {
        products: frizbitProducts,
        coupon: coupon ? coupon.code : null,
        currency,
        discount: order.discountAmount.gross,
        orderId: order.id,
        total: value,
      });
    }),

    // Pinterest
    retryOperation(async () => {
      if (!window.pintrk) {
        throw new Error("Pinterest not ready");
      }
      if (hasPinterestAppID) {
        window.pintrk("track", "checkout", {
          value,
          order_quantity: products.length,
          currency,
          order_id: order.id,
          line_items: pinterestProducts,
        });
      }
    }),
  ]);
};

/* Analytics V2 */
const getMultipleProductInfo = (products: Cart[]) => {
  let value = 0;

  for (let index = 0; index < products.length; index++) {
    const item = products[index];
    value += (item?.price?.finalPrice ?? 0) * (item?.quantity ?? 1);
  }

  return {
    currency: products?.[0]?.price?.currency,
    value,
    items: products.map((item) => ({
      item_id: item?.product.id,
      item_name: item?.product?.name,
      quantity: item?.quantity,
      price: item?.price?.finalPrice,
    })),
  };
};

const getProductInfo = (item: Cart & { quantity: number }) => {
  return {
    currency: item?.price?.currency,
    value: (item?.price?.finalPrice ?? 0) * (item?.quantity ?? 0),
    items: [
      {
        item_id: item?.product?.id,
        item_name: item?.product?.name,
        item_brand: item?.product?.brand,
        item_category: item?.product?.categories?.[0], // NOTE: This value just exist in product page endpoint used in view_item
        item_category2: item?.product?.categories?.[1], // NOTE: This value just exist in product page endpoint used in view_item
        item_variant: undefined, // NOTE: This value doesnt exist
        item_size: undefined, // NOTE: This value doesnt exist
        quantity: item?.quantity,
        price: item?.price?.finalPrice,
      },
    ],
  };
};

const validateExistGtag = () => {
  return window?.gtag !== undefined;
};

export const identify = async ({ id, user }: IdentifyProps) => {
  // We need to merge users in Recombee, so previous userId (anonymous) will merge with new user
  // https://docs.recombee.com/api.html#users - Merge users
  // TODO: By the time, MergeUsers can only be performed on server side
  // const prevId = posthog.get_distinct_id();
  // recombeeClient.send(new window.recombee.MergeUsers(id, prevId, true));

  if (window?.gtag) {
    window.gtag("set", "userId", id);
  }

  posthog.identify(id);
  posthog.people.set({ email: user.email, firstName: user.firstName, telephone: user.phone });

  await Promise.all([
    // Crisp
    retryOperation(async () => {
      if (!window.$crisp) {
        throw new Error("Crisp not ready");
      }
      // Set user public id to identify the user in Crisp
      window.CRISP_TOKEN_ID = id;

      if (window?.$crisp) {
        window.$crisp.push(["do", "session:reset"]);
        window.$crisp.push(["set", "user:email", [user.email]]);
        window.$crisp.push(["set", "user:phone", [user.phone]]);
        window.$crisp.push(["set", "user:nickname", [`${user.firstName} ${user.lastName}`]]);
      }
    }),

    // Klaviyo
    retryOperation(async () => {
      if (!window?._learnq) {
        throw new Error("Klaviyo not ready");
      }
      window._learnq.push([
        "identify",
        {
          id,
          $email: user.email,
          $first_name: user.firstName,
          $last_name: user.lastName,
          $phone_number: user.phone,
          created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
          membership: user.membership === undefined ? null : user.membership,
        },
      ]);
    }),

    // frizbit
    retryOperation(async () => {
      if (!window?.frizbit) {
        throw new Error("Frizbit not ready");
      }
      window.frizbit?.identify(id, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        phone: user.phone,
        smsOptin: true,
        emailOptin: true,
        created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
      });
    }),
  ]);
};

/* Page view */
const sendEventLocationPageView = () => {
  if (!validateExistGtag()) return;

  window.gtag("event", "page_view", {
    page_title: document.title,
    page_location: window.location.href,
  });
};

const sendEventPathnamePageView = (pathname: string) => {
  if (!validateExistGtag()) return;

  window.gtag("event", "page_view", {
    page_title: document.title,
    page_path: pathname,
  });
};

const sendEventTimingPageView = () => {
  if (!validateExistGtag()) return;

  window.gtag("event", "page_view", {
    page_load_time: performance?.now(),
  });
};

const sendEventScrollPageView = (scrollDepth: number) => {
  if (!validateExistGtag()) return;

  window.gtag("event", "page_view", {
    page_title: document.title,
    scroll_depth: `${scrollDepth}%`,
  });
};

const sendEventPageViewCategory = (pathname: string, userType: TUserType) => {
  if (!validateExistGtag()) return;

  const sendEvent = (pageCategory: string) => {
    window.gtag("event", "page_view", {
      page_title: document.title,
      page_location: window.location.href,
      user_type: userType, // 'logged_in' o 'guest'
      page_category: pageCategory, // ejemplo: 'product' , 'home', 'category', 'checkout']
      device_type: detectDeviceType(), // 'mobile'  o 'desktop', 'tablet'
    });
  };

  if (pathname === "/") {
    sendEvent("home");
  }

  for (let index = 0; index < allPagesViewPage.length; index++) {
    const item = allPagesViewPage[index];

    if (pathname.startsWith(item.startWith)) {
      sendEvent(item.page);
      break;
    }
  }
};

export const pageView = {
  eventLocation: sendEventLocationPageView,
  eventPathname: sendEventPathnamePageView,
  eventTiming: sendEventTimingPageView,
  eventPageScroll: sendEventScrollPageView,
  eventPageCategory: sendEventPageViewCategory,
};

/* Add shipping info */
const sendEventShippingInfoMethod = ({
  currency,
  value,
  shippingMethod,
  estimatedDeliveryDate,
}: ISendEventShipingInfoMethod) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_shipping_info", {
    currency,
    value,
    shipping_method: shippingMethod,
    estimated_delivery_date: formatDate(estimatedDeliveryDate, "YYYY-MM-DD"),
  });
};

const sendEventShippingInfoUser = ({
  currency,
  value,
  userId,
  userType,
  isFirstPurchase,
}: ISendEventShipingInfoUser) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_shipping_info", {
    currency,
    value,
    user_type: userType,
    user_id: userId,
    is_first_purchase: isFirstPurchase,
  });
};

const sendEventShippingInfoAddress = ({ currency, value, shippingAddress }: ISendEventShipingInfoAddress) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_shipping_info", {
    currency,
    value,
    shipping_address: shippingAddress,
  });
};

export const addShippingInfo = {
  eventInfoMethod: sendEventShippingInfoMethod,
  eventInfoUser: sendEventShippingInfoUser,
  eventInfoAddress: sendEventShippingInfoAddress,
};

/* Add payment info */
const sendEventAddPaymentInfo = ({
  currency,
  value,
  paymentType,
  cardType,
  cardLastFour,
}: ISendEventAddPaymentInfo) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_payment_info", {
    currency,
    value,
    payment_type: paymentType, // pse | credit_card | bank_terminal
    card_type: cardType,
    card_last_four: cardLastFour,
  });
};

export const addPaymentInfo = {
  eventPaymentInfo: sendEventAddPaymentInfo,
};

/* Add cart to cart */
// Use this function only if you add two or more products, if its just one product then use `sendEventCartProduct`
// NOTE: If is multiple doesnt if use sendEventCartProduct and sendEventCartDiscountInfo from here
const sendEventCartMultipleProducts = (products: Cart[]) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_to_cart", getMultipleProductInfo(products));
};

const sendEventCartProduct = (product: Cart) => {
  if (!validateExistGtag()) return;
  sendEventCartDiscountInfo(product);
  window.gtag("event", "add_to_cart", getProductInfo(product));
};

const sendEventCartDiscountInfo = (product: Cart) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_to_cart", {
    currency: product?.price?.currency,
    value: (product?.price?.finalPrice ?? 0) * (product?.quantity ?? 0),
    items: [
      {
        item_id: product?.product.id,
        item_name: product?.product?.name,
        original_price: product.price.originalPrice,
        discount: product.price.discount,
        coupon: undefined, // NOTE: This value doesnt exist
        quantity: product?.quantity,
        price: product?.price.finalPrice,
      },
    ],
  });
};

export const addToCart = {
  eventMultipleProducts: sendEventCartMultipleProducts,
  eventProduct: sendEventCartProduct,
};

/* Begin checkout */
const sendEventCheckoutCartInfo = (cartProducts: Cart[], total: number) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "begin_checkout", {
    currency: cartProducts?.[0]?.price?.currency,
    value: total,
    items: cartProducts.map((item) => ({
      item_id: item?.product.id,
      item_name: item?.product?.name,
      quantity: item?.quantity,
      price: item?.price?.finalPrice,
    })),
  });
};

const sendEventCheckoutUserInfo = ({
  currency,
  value,
  userId,
  userType,
  isFirstPurchase,
}: ISendEventShipingInfoUser) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "begin_checkout", {
    currency,
    value,
    userId,
    userType,
    isFirstPurchase,
  });
};

export const beginCheckout = {
  eventCartInfo: sendEventCheckoutCartInfo,
  eventUserInfo: sendEventCheckoutUserInfo,
};

/* Sign up */
const sendEventSignUpUserInfo = ({ method, userId }: { method: string; userId?: string }) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "sign_up", {
    method, // 'email' or 'telephone'
    user_id: userId,
    user_type: "new", // NOTE: What are the other options?
  });
};

export const signUp = {
  eventUserInfo: sendEventSignUpUserInfo,
};

/* Remove cart */
// NOTE: This function is not used because we do not have an option to remove multiple products
const sendEventRemoveCartMultipleProducts = (products: Cart[]) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "remove_from_cart", getMultipleProductInfo(products));
};

const sendEventRemoveCartProduct = (product: Cart) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "remove_from_cart", getProductInfo(product));
};

export const removeFromCart = {
  eventMultipleProducts: sendEventRemoveCartMultipleProducts,
  eventProduct: sendEventRemoveCartProduct,
};

/* View cart */
const sendEventViewCartMultipleProducts = (products: Cart[]) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_cart", getMultipleProductInfo(products));
};

const sendEventViewCartProduct = (product: Cart) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_cart", getProductInfo(product));
};

const sendEventViewCartUser = ({
  product,
  userType,
  userId,
}: {
  product: Cart;
  userType: TUserType;
  userId: string;
}) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_cart", {
    ...getMultipleProductInfo([product]),
    user_type: userType,
    user_id: userId,
  });
};

export const viewCart = {
  eventMultipleProducts: sendEventViewCartMultipleProducts,
  eventProduct: sendEventViewCartProduct,
  eventUserInfo: sendEventViewCartUser,
};

/* View promotion */
const sendEventViewPromotionBasic = (promotions: ISendEventViewPromotionBasic) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_promotion", {
    promotions: promotions.map((item) => ({
      promotion_id: item.promotionId,
      promotion_name: item.promotionName,
      creative_name: item.creativeName,
      creative_slot: item.creativeSlot,
      location_id: item.locationId,
    })),
  });
};

export const viewPromotion = {
  eventBasic: sendEventViewPromotionBasic,
};

/* View item */
const sendEventViewItemInfo = (product: Cart) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_item", getProductInfo({ ...product, quantity: 1 }));
};

export const viewItem = {
  eventViewItemInfo: sendEventViewItemInfo,
};

/* Login */
const sendEventLogin = ({ userId }: { userId: string }) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "login", {
    method: "email",
    user_id: userId,
    user_type: "logged_in", // NOTE: It is always logged_in
  });
};

export const login = {
  loginBasic: sendEventLogin,
};

/* Purchase */
const sendEventPurchaseUserInfo = ({
  transactionId,
  value,
  currency,
  userId,
  userType,
  items,
}: ISendEventPurchaseUserInfo) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "purchase", {
    transaction_id: transactionId,
    value,
    currency,
    user_id: userId,
    user_type: userType,
    items,
  });
};

const sendEventPurchaseDiscountInfo = ({
  transactionId,
  value,
  currency,
  coupon,
  discountAmount,
  items,
}: ISendEventPurchaseDiscountInfo) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "purchase", {
    transaction_id: transactionId,
    value,
    currency,
    discount_amount: discountAmount,
    coupon,
    items,
  });
};

export const purchase = {
  userInfo: sendEventPurchaseUserInfo,
  discountInfo: sendEventPurchaseDiscountInfo,
};

/* Generate lead */
const sendEventGenerateLeadForm = ({
  value,
  currency,
  formName,
  formLength,
  formFields,
}: ISendEventGenerateLeadForm) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "generate_lead", {
    currency,
    value,
    form_name: formName,
    form_length: formLength,
    form_fields: formFields,
  });
};

export const generateLead = {
  generateLeadForm: sendEventGenerateLeadForm,
};
